import { Menu } from 'react-feather';

import Logo from '../Assets/logo.png'

const Navbar = () => {

	return (

		<div id="navbar" className="uk-border-bottom uk-background-default">
			<div className="uk-container uk-container-xlarge">
				<nav data-uk-navbar>
					<div className="uk-navbar-left">
						<div className="uk-navbar-item" data-uk-toggle="target: #menu">
							<Menu/>
						</div>
						<div className="uk-logo uk-margin-small-left">
							<img src={Logo} alt="" id="logo"/>
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
}

export default Navbar;