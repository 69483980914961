import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const AdminMembersPage = React.lazy(() => import('../Pages/AdminMembers/AdminMembersPage'));

const AddAdminMemberPage = React.lazy(() => import('../Pages/AdminMembers/AddAdminMemberPage'));

const UpdateAdminMemberPage = React.lazy(() => import('../Pages/AdminMembers/UpdateAdminMemberPage'));

export default [

	{
		path : '/admins',
		element : <AuthGuard element={<AdminMembersPage/>} required={true}/>
	},

	{
		path : '/admins/add',
		element : <AuthGuard element={<AddAdminMemberPage/>} required={true}/>
	},

	{
		path : '/admins/update/:adminId',
		element : <AuthGuard element={<UpdateAdminMemberPage/>} required={true}/>
	}
];