import React, { Suspense } from 'react';

import useAuthStore from './Store/useAuthStore';

import classNames from 'classnames';

import Router from './Routes/Router';

import { FullPageLoader } from './Components/Loaders';

import Navbar from './Components/Navbar';

import Menu from './Components/Menu';

const App = () => {

	const isLoggedIn = useAuthStore(state => state.isLoggedIn);

	const classes = classNames({

		'main-page-padding' : isLoggedIn
	});

	return (
		
		<Suspense fallback={<FullPageLoader/>}>

			{/* navbar */}
			{ isLoggedIn && <Navbar/> }

			<Menu/>

			<main className={classes}>
				<Router/>
			</main>
		</Suspense>
	)
}

export default App;