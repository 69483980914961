export const APP_NAME = 'LIBÉLO';

export const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/admin' : 'https://api.libelo-dz.com/admin';

export const APP_KEY = 'ltwYAlQVhTSNjz7y4kg4wNOcbSY+3Nmt0zLbTlrwuCo=';

export const ORDER_STATUS = {

	WAITING_STORE_ACCEPTANCE : 'waiting_store_acceptance',
	CANCELLED_BY_STORE : 'cancelled_by_store',
	ACCEPTED_BY_STORE : 'accepted_by_store',
	TAKEN_BY_DELIVERER : 'taken_by_deliverer',
	CANCELLED_BY_DELIVERER : 'cancelled_by_deliverer',
	CANCELLED_BY_CLIENT : 'cancelled_by_client',
	CANCELLED_BY_ADMIN : 'cancelled_by_admin',
	DELIVERED : 'delivered'
}

export const SUBSCRIPTION_ORDER_STATUS = {

	WAITING_ADMIN_ACCEPTANCE : 'waiting_admin_acceptance',
	ACCEPTED_BY_ADMIN : 'accepted_by_admin',
	REFUSED_BY_ADMIN : 'refused_by_admin',
	CANCELLED_BY_CLIENT : 'cancelled_by_client',
	CANCELLED_BY_ADMIN : 'cancelled_by_admin',
}

export const POST_STATUS = {

	AVAILABLE : 'available',
	UNAVAILABLE : 'unavailable'
}

export const SUBSCRIPTION_ORDER_PAYMENT_MODES = {

	ALL_AT_ONCE : 'all_at_once',
	PER_DELIVERY : 'per_delivery'
}

export const ACCOUNT_STATUS = {

	ACTIVE : 'active',
	BLOCKED : 'blocked'
}

export const REVENUES_GROUP_BY = {

	DAYS : 'days',
	MONTHS : 'months',
	YEARS : 'years'
}