import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const UsersPage = React.lazy(() => import('../Pages/Users/UsersPage'));

const UserProfilePage = React.lazy(() => import('../Pages/Users/UserProfilePage'));

const UserExpressOrdersPage = React.lazy(() => import('../Pages/Users/UserExpressOrdersPage'));

const UserSubscriptionOrdersPage = React.lazy(() => import('../Pages/Users/UserSubscriptionOrdersPage'));

export default [

	{
		path : '/users',
		element : <AuthGuard element={<UsersPage/>} required={true}/>
	},

	{
		path : '/users/profile/:userId',
		element : <AuthGuard element={<UserProfilePage/>} required={true}/>,

		
		children : [

			{
				path : '',
				element : <UserExpressOrdersPage/>
			},

			{
				path : 'subscription-orders',
				element : <UserSubscriptionOrdersPage/>
			}
		]
	}
];