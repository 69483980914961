import React from 'react';

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { ConfirmAlert } from './Alerts';

import { LogOut, Home, Users, Shield, MapPin, Package, Percent } from "react-feather";

import Logo from '../Assets/logo.png'

const Menu = () => {

	const navigate = useNavigate();

	const location = useLocation();

	const logout = (e) => {

		e.preventDefault();

		ConfirmAlert('Voulez-vous vraiment vous déconnecter ?').then(() => {

			navigate('/logout');
		}).catch(err => {

			console.log(err);
		})
	}

	React.useEffect(() => {

		UIkit.offcanvas('#menu').hide();
	}, [location])

	return (

		<div id="menu" data-uk-offcanvas="overlay: true">

			<div className="uk-offcanvas-bar uk-background-default">

				<button className="uk-offcanvas-close" type="button" data-uk-close></button>

				<ul className="uk-nav uk-nav-default">

					<div className="uk-margin-small">
						<img src={Logo} alt="" width="150" />
					</div>

					<li className="uk-margin-small">
						<NavLink to="/">
							<Home size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">accueil</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/users">
							<Users size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">utilisateurs</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/stores">
							<ion-icon name="storefront-outline" style={{ fontSize : 24 }}></ion-icon>
							<span className="first-letter-capitalize uk-margin-small-left">boutiques</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/deliverers">
							<ion-icon name="bicycle-outline" style={{ fontSize : 24 }}></ion-icon>
							<span className="first-letter-capitalize uk-margin-small-left">livreurs</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/express-orders">
							<Package size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">commandes express</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/subscription-orders">
							<Package size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">abonnements</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/wilayas-regions">
							<MapPin size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">wilayas et regions</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/delivery-prices">
							<Percent size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">prix et pourcentage</span>
						</NavLink>
					</li>

					<li className="uk-margin-small">
						<NavLink to="/admins">
							<Shield size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">administrateurs</span>
						</NavLink>
					</li>

					<li className="uk-nav-divider"></li>

					<li className="uk-margin-small">
						<a onClick={logout}>
							<LogOut size={22}/>
							<span className="first-letter-capitalize uk-margin-small-left">déconnexion</span>
						</a>
					</li>
				</ul>

			</div>
		</div>
	)
}

export default Menu