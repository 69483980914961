import create from "zustand";

const initState = {

	allFilters : {

		status : '',

		wilaya : '',

		regionId : '',

		categoryId : '',

		days : []
	},

	waitingFilters : {

		wilaya : '',

		regionId : '',

		categoryId : '',

		days : []
	},

	todayFilters : {

		wilaya : '',

		regionId : '',

		categoryId : ''
	},

	tomorrowFilters : {

		wilaya : '',

		regionId : '',

		categoryId : ''
	}
}

const useSubscriptionOrdersStore = create((set, get) => ({

	...initState,

	setAllFilter : (filter) => set(state => ({ allFilters : { ...state.allFilters, ...filter } })),

	setWaitingFilter : (filter) => set(state => ({ waitingFilters : { ...state.waitingFilters, ...filter } })),

	setTodayFilter : (filter) => set(state => ({ todayFilters : { ...state.todayFilters, ...filter } })),

	setTomorrowFilter : (filter) => set(state => ({ tomorrowFilters : { ...state.tomorrowFilters, ...filter } })),

	reset : () => set(initState),
}));

export default useSubscriptionOrdersStore