import React, { Component } from 'react';

import errorIllustration from '../Assets/Illustrations/error.svg';

import noDataIllustration from '../Assets/Illustrations/no-data.svg';

import permissionIllustration from '../Assets/Illustrations/permission.svg';

export const AlertDanger = ({ message }) => {

	return (

		<div data-uk-alert className="uk-alert-danger first-letter-capitalize uk-border-rounded">
			{ message }
		</div>
	)
}

export const AlertPrimary = ({ message }) => {

	return (

		<div data-uk-alert className="uk-alert-primary first-letter-capitalize uk-border-rounded">
			{ message }
		</div>
	)
}

export const FetchErrorAlert = ({ tryAgain }) => {

	return (

		<div id="error-alert" className="uk-text-center">
			<img src={errorIllustration} alt="une erreur est survenue" className="uk-width-2-5"/>
			<p className="uk-text-lead first-letter-capitalize">une erreur est survenue, <span onClick={tryAgain} className="uk-link">réessayez</span>.</p>
		</div>
	);
}


export const NoDataAlert = ({ message }) => {

	return (

		<div id="no-data-alert" className="uk-text-center">
			<img src={noDataIllustration} alt="aucune donnée à afficher" className="uk-width-2-5"/>
			<p className="uk-text-lead first-letter-capitalize uk-text-center"> { message || 'aucune donnée à afficher.' }</p>
		</div>
	)
}

export const PermissionAlert = ({ message }) => {

	return (

		<div id="permission-alert" className="uk-text-center">
			<img src={permissionIllustration} alt="une erreur est survenue" className="uk-width-2-5"/>
			<p className="uk-text-lead first-letter-capitalize">{ message || 'vous ne pouvez pas effectuer cette action.' }</p>
		</div>
	);
}

export const ConfirmAlert = (text) => {

	if (document.querySelector('[data-uk-dropdown]')) {

		UIkit.dropdown('[data-uk-dropdown]').hide(false);
	}

	const modal = UIkit.modal.confirm(text, {

		labels : { ok : 'confirmer', cancel : 'annuler' },
	});

	modal.dialog.$el.classList.add('modal-confirm-alert');

	modal.dialog.$el.querySelector('.uk-modal-body').classList.add('uk-h3', 'uk-margin-remove',  'first-letter-capitalize')		

	return modal;
}

export const PromptAlert = (text, options={}) => {

	if (document.querySelector('[data-uk-dropdown]')) {

		UIkit.dropdown('[data-uk-dropdown]').hide(false);
	}

	const modal = UIkit.modal.prompt(text, '', {

		labels : { ok : 'confirmer', cancel : 'annuler' },
	});

	modal.dialog.$el.classList.add('modal-prompt-alert');

	modal.dialog.$el.querySelector('.uk-modal-body label').classList.add('uk-text-lead', 'first-letter-capitalize', 'uk-margin-small-bottom');

	const input = modal.dialog.$el.querySelector('.uk-modal-body input');

	input.type = options.password && options.password === true ? 'password' : 'text';

	input.placeholder = options.placeholder || '';

	input.classList.add('uk-border-rounded');

	return modal;
}

export const InfosAlert = ({ title, text }) => {

	return (

		<div id="infos-alert" data-uk-modal>
			<div className="uk-modal-dialog">
				<div className="uk-modal-body">
					<h2 className="uk-modal-title first-letter-capitalize uk-margin-small">{ title }</h2>
					<p className="uk-margin-remove">{ text }</p>
				</div>
				<div className="uk-modal-footer uk-text-right">
					<button className="uk-button uk-button-default uk-button-rounded uk-modal-close">fermer</button>
				</div>
			</div>
		</div>
	)
}