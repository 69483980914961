import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const ExpressOrdersPage = React.lazy(() => import('../Pages/ExpressOrders/ExpressOrdersPage'));

const ExpressOrderDetailsPage = React.lazy(() => import('../Pages/ExpressOrders/ExpressOrdersDetailsPage'));

export default [

	{
		path : '/express-orders',
		element : <AuthGuard element={<ExpressOrdersPage/>} required={true}/>
	},

	{
		path : '/express-orders/details/:orderId',
		element : <AuthGuard element={<ExpressOrderDetailsPage/>} required={true}/>
	},
];