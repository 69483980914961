import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';

import moment from 'moment';

import 'moment/locale/fr';

import './Styles';

import { QueryClientProvider, QueryClient } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

import { BrowserRouter } from 'react-router-dom';

import { APP_NAME } from './Constants/Constants';

moment.locale('fr');

window.APP_NAME = APP_NAME;

const queryClient = new QueryClient({

	defaultOptions : {

		queries : {

			refetchOnWindowFocus : false
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
			<ReactQueryDevtools position="bottom-right"/>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
