import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const WilayasRegionsPage = React.lazy(() => import('../Pages/WilayasRegions/WilayasRegionsPage'));

const AddWilayaPage = React.lazy(() => import('../Pages/WilayasRegions/AddWilayaPage'));

const UpdateWilayaPage = React.lazy(() => import('../Pages/WilayasRegions/UpdateWilayaPage'));

const AddRegionWilayaPage = React.lazy(() => import('../Pages/WilayasRegions/AddRegionWilayaPage'));

export default [

	{
		path : '/wilayas-regions',
		element : <AuthGuard element={<WilayasRegionsPage/>} required={true}/>
	},

	{
		path : '/wilayas-regions/add-wilaya',
		element : <AuthGuard element={<AddWilayaPage/>} required={true}/>
	},

	{
		path : '/wilayas-regions/update-wilaya/:wilaya',
		element : <AuthGuard element={<UpdateWilayaPage/>} required={true}/>
	},

	{
		path : '/wilayas-regions/add-region/:wilaya',
		element : <AuthGuard element={<AddRegionWilayaPage/>} required={true}/>
	}
];