import create from "zustand";

const initState = {

	filters : {

		wilaya : '',

		regionId : ''
	}
}

const useUsersStore = create((set, get) => ({

	...initState,

	setFilter : (filter) => set(state => ({ filters : { ...state.filters, ...filter } })),

	reset : () => set(initState),
}));

export default useUsersStore