import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const StoresPage = React.lazy(() => import('../Pages/Stores/StoresPage'));

const AddStorePage = React.lazy(() => import('../Pages/Stores/AddStorePage'));

const UpdateStorePage = React.lazy(() => import('../Pages/Stores/UpdateStorePage'));

const StoreProfilePage = React.lazy(() => import('../Pages/Stores/StoreProfilePage'));

const StoreMenusPage = React.lazy(() => import('../Pages/Stores/StoreMenusPage'));

const StoreMenuPostsPage = React.lazy(() => import('../Pages/Stores/StoreMenuPostsPage'));

const StoreExpressOrdersPage = React.lazy(() => import('../Pages/Stores/StoreExpressOrdersPage'));

const StoreSubscriptionOrdersPage = React.lazy(() => import('../Pages/Stores/StoreSubscriptionOrdersPage'));

const StoreExpressOrdersRevenuesPage = React.lazy(() => import('../Pages/Stores/StoreExpressOrdersRevenuesPage'));

const StoreSubscriptionOrdersRevenuesPage = React.lazy(() => import('../Pages/Stores/StoreSubscriptionOrdersRevenuesPage'));

export default [

	{
		path : '/stores',
		element : <AuthGuard element={<StoresPage/>} required={true}/>
	},

	{
		path : '/stores/add',
		element : <AuthGuard element={<AddStorePage/>} required={true}/>
	},

	{
		path : '/stores/update/:storeId',
		element : <AuthGuard element={<UpdateStorePage/>} required={true}/>
	},

	{
		path : '/stores/profile/:storeId',
		element : <AuthGuard element={<StoreProfilePage/>} required={true}/>,
		children : [

			{
				path : '',
				element : <StoreMenusPage/>
			},

			{
				path : 'express-orders',
				element : <StoreExpressOrdersPage/>
			},

			{
				path : 'subscription-orders',
				element : <StoreSubscriptionOrdersPage/>
			},

			{
				path : 'express-orders-revenues',
				element : <StoreExpressOrdersRevenuesPage/>
			},

			{
				path : 'subscription-orders-revenues',
				element : <StoreSubscriptionOrdersRevenuesPage/>
			},
		]
	},

	{
		path : '/stores/:storeId/menu-posts/:menuId',
		element : <AuthGuard element={<StoreMenuPostsPage/>} required={true}/>
	},
];