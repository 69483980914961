import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const PostDetailsPage = React.lazy(() => import('../Pages/Posts/PostDetailsPage'));

export default [

	{
		path : '/posts/details/:postId',
		element : <AuthGuard element={<PostDetailsPage/>} required={true}/>
	}
];