import React from 'react';

export const Spinner = ({ ...rest}) => {

	return (

		<div className="spinner uk-padding-remove uk-text-middle"></div>
	)
}

export const FullPageLoader = () => {

	return (

		<div className="full-page-loader uk-overlay uk-overlay-default uk-position-fixed uk-position-cover uk-flex uk-flex-middle uk-flex-center">
			<Spinner/>
		</div>
	)
}

export const DataLoader = () => {

	return (

		<div id="data-loader" className="uk-flex uk-flex-center">
			<Spinner/>
		</div>
	)
}