import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const DeliveryPricesPage = React.lazy(() => import('../Pages/DeliveryPrices/DeliveryPricesPage'));

export default [

	{
		path : '/delivery-prices',
		element : <AuthGuard element={<DeliveryPricesPage/>} required={true}/>
	}
];