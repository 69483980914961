import React from 'react';

import { useRoutes } from 'react-router-dom';

import AuthGuard from '../Guards/AuthGuard';

import UnAuthGuard from '../Guards/UnAuthGuard';

import StoresRoutes from './StoresRoutes';

import PostsRoutes from './PostsRoutes';

import DeliverersRoutes from './DeliverersRoutes';

import AdminMembersRoutes from './AdminMembersRoutes';

import UsersRoutes from './UsersRoutes';

import ExpressOrdersRoutes from './ExpressOrdersRoutes';

import SubscriptionOrdersRoutes from './SubscriptionOrdersRoutes';

import WilayasRegionsRoutes from './WilayasRegionsRoutes';

import DeliveryPricesRoutes from './DeliveryPricesRoutes';

const HomePage = React.lazy(() => import('../Pages/Home/HomePage'));

const LoginPage = React.lazy(() => import('../Pages/Login/LoginPage'));

const Logout = React.lazy(() => import('../Pages/Logout/LogoutPage'));

const NotFoundPage = React.lazy(() => import('../Pages/NotFound/NotFoundPage'));

const Router = () => {

	const routes = useRoutes([

		{
			path : '/',
			element : <AuthGuard element={<HomePage/>} required={true}/>
		},

		{
			path : '/login',
			element : <UnAuthGuard element={<LoginPage/>}/>
		},

		{
			path : '/logout',
			element : <AuthGuard element={<Logout/>} required={true}/>
		},

		...StoresRoutes,

		...PostsRoutes,

		...DeliverersRoutes,
		
		...AdminMembersRoutes,

		...UsersRoutes,

		...ExpressOrdersRoutes,

		...SubscriptionOrdersRoutes,

		...WilayasRegionsRoutes,

		...DeliveryPricesRoutes,

		{
			path : '*',
			element : <NotFoundPage/>
		}
	])

	return routes;
}

export default Router;