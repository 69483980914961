import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const DeliverersPage = React.lazy(() => import('../Pages/Deliverers/DeliverersPage'));

const AddDelivererPage = React.lazy(() => import('../Pages/Deliverers/AddDelivererPage'));

const UpdateDelivererPage = React.lazy(() => import('../Pages/Deliverers/UpdateDelivererPage'));

const DelivererProfilePage = React.lazy(() => import('../Pages/Deliverers/DelivererProfilePage'));

const DelivererExpressOrdersPage = React.lazy(() => import('../Pages/Deliverers/DelivererExpressOrdersPage'));

const DelivererSubscriptionOrdersPage = React.lazy(() => import('../Pages/Deliverers/DelivererSubscriptionOrdersPage'));

const DelivererExpressOrdersRevenuesPage = React.lazy(() => import('../Pages/Deliverers/DelivererExpressOrdersRevenuesPage'));

const DelivererSubscriptionOrdersRevenuesPage = React.lazy(() => import('../Pages/Deliverers/DelivererSubscriptionOrdersRevenuesPage'));

export default [

	{
		path : '/deliverers',
		element : <AuthGuard element={<DeliverersPage/>} required={true}/>
	},
	
	{
		path : '/deliverers/add',
		element : <AuthGuard element={<AddDelivererPage/>} required={true}/>
	},

	{
		path : '/deliverers/update/:delivererId',
		element : <AuthGuard element={<UpdateDelivererPage/>} required={true}/>
	},

	{
		path : '/deliverers/profile/:delivererId',
		element : <AuthGuard element={<DelivererProfilePage/>} required={true}/>,
		children : [

			{
				path : '',
				element : <DelivererExpressOrdersPage/>
			},

			{
				path : 'express-orders-revenues',
				element : <DelivererExpressOrdersRevenuesPage/>
			},

			{
				path : 'subscription-orders-revenues',
				element : <DelivererSubscriptionOrdersRevenuesPage/>
			},

			{
				path : 'subscription-orders',
				element : <DelivererSubscriptionOrdersPage/>
			}
		]
	}
];