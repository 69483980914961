import React from 'react';

import AuthGuard from '../Guards/AuthGuard';

const SubscriptionOrdersPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrdersPage'));

const SubscriptionOrdersAllPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrdersAllPage'));

const SubscriptionOrdersWaitingPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrdersWaitingPage'));

const SubscriptionOrdersTodayPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrdersTodayPage'));

const SubscriptionOrdersTomorrowPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrdersTomorrowPage'));

const SubscriptionOrderDetailsPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrderDetailsPage'));

const SubscriptionOrderDetailsInvoicePage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrderDetailsInvoicePage'));

const SubscriptionOrderDeliveriesPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrderDeliveriesPage'));

const SubscriptionOrderAssignmentsPage = React.lazy(() => import('../Pages/SubscriptionOrders/SubscriptionOrderAssignmentsPage'));

const UpdateSubscriptionOrderInfosPage = React.lazy(() => import('../Pages/SubscriptionOrders/UpdateSubscriptionOrderInfosPage'));

export default [

	{
		path : '/subscription-orders',
		element : <AuthGuard element={<SubscriptionOrdersPage/>} required={true}/>,
		children : [

			{
				path : '',
				element : <AuthGuard element={<SubscriptionOrdersAllPage/>} required={true}/>
			},

			{
				path : 'waiting',
				element : <AuthGuard element={<SubscriptionOrdersWaitingPage/>} required={true}/>
			},

			{
				path : 'today',
				element : <AuthGuard element={<SubscriptionOrdersTodayPage/>} required={true}/>
			},

			{
				path : 'tomorrow',
				element : <AuthGuard element={<SubscriptionOrdersTomorrowPage/>} required={true}/>
			}
		]
	},

	{
		path : '/subscription-orders/details/:orderId',
		element : <AuthGuard element={<SubscriptionOrderDetailsPage/>} required={true}/>,
		children : [

			{
				path : '',
				element : <AuthGuard element={<SubscriptionOrderDetailsInvoicePage/>} required={true}/>
			},

			{
				path : 'assignments',
				element : <AuthGuard element={<SubscriptionOrderAssignmentsPage/>} required={true}/>
			},

			{
				path : 'deliveries',
				element : <AuthGuard element={<SubscriptionOrderDeliveriesPage/>} required={true}/>
			}
		]
	},

	{
		path : '/subscription-orders/update/:orderId',
		element : <AuthGuard element={<UpdateSubscriptionOrderInfosPage/>} required={true}/>,
	}
];